import $ from "jquery";
import "slick-carousel";

function initEventsSlider() {
  if ($(".js-events-slider").length) {
    $(".js-events-slider").slick({
      arrows: true,
      dots: false,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
}

$(function () {
  initEventsSlider();
});
